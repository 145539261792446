<template>
  <path d="M15.222 7.59688C13.7407 4.47656 11.5017 2.90625 8.50009 2.90625C5.49697 2.90625 3.25947 4.47656 1.77822 7.59844C1.7188 7.72425 1.68799 7.86165 1.68799 8.00078C1.68799 8.13991 1.7188 8.27732 1.77822 8.40312C3.25947 11.5234 5.49853 13.0938 8.50009 13.0938C11.5032 13.0938 13.7407 11.5234 15.222 8.40156C15.3423 8.14844 15.3423 7.85469 15.222 7.59688ZM8.50009 11.9688C5.97978 11.9688 4.13447 10.6906 2.8329 8C4.13447 5.30938 5.97978 4.03125 8.50009 4.03125C11.0204 4.03125 12.8657 5.30938 14.1673 8C12.8673 10.6906 11.022 11.9688 8.50009 11.9688ZM8.43759 5.25C6.91884 5.25 5.68759 6.48125 5.68759 8C5.68759 9.51875 6.91884 10.75 8.43759 10.75C9.95634 10.75 11.1876 9.51875 11.1876 8C11.1876 6.48125 9.95634 5.25 8.43759 5.25ZM8.43759 9.75C7.4704 9.75 6.68759 8.96719 6.68759 8C6.68759 7.03281 7.4704 6.25 8.43759 6.25C9.40478 6.25 10.1876 7.03281 10.1876 8C10.1876 8.96719 9.40478 9.75 8.43759 9.75Z" fill="currentColor"/>
</template>

<script>
export default {
  name: 'VisibleIcon'
}
</script>

<style scoped>

</style>
