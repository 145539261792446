
import { computed, defineComponent } from 'vue';
import partnerAPI from '@/service/partnerAPI';
import Close from '@/components/icons/Close.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'RefundReasonModal',
  props: {
    isOpen: {
      type: Boolean,
    },
    order: {
      type: Object,
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const input = computed(() => {
      return {
        orderId: props.order?.orderId,
        returnMemo: props.order?.returnMemo,
      };
    });

    const submit = async () => {
      try {
        await partnerAPI.partnerProduct.updateReturnMemoUsingPUT({
          param: {
            ...input.value,
          },
        });
        alert('저장되었습니다.');
        onClose();
      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const onClose = () => {
      console.log('emit');
      emit('onClose');
    };

    return {
      input,
      submit,
      onClose,
    };
  },
});
