<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.222 7.5972C14.6727 6.44002 14.019 5.49627 13.261 4.76595L12.466 5.56095C13.1143 6.18048 13.679 6.99095 14.1673 8.00033C12.8673 10.691 11.022 11.9691 8.50009 11.9691C7.74311 11.9691 7.04587 11.8525 6.40837 11.6194L5.54696 12.4808C6.43238 12.8897 7.41676 13.0941 8.50009 13.0941C11.5032 13.0941 13.7438 11.53 15.222 8.40189C15.2814 8.27609 15.3122 8.13868 15.3122 7.99955C15.3122 7.86041 15.2814 7.72301 15.222 7.5972ZM14.2287 2.5872L13.5626 1.92033C13.551 1.90871 13.5372 1.89949 13.522 1.8932C13.5068 1.8869 13.4906 1.88367 13.4742 1.88367C13.4577 1.88367 13.4415 1.8869 13.4263 1.8932C13.4111 1.89949 13.3973 1.90871 13.3857 1.92033L11.6768 3.62845C10.7346 3.1472 9.67571 2.90658 8.50009 2.90658C5.49696 2.90658 3.25634 4.47064 1.77822 7.59877C1.7188 7.72457 1.68799 7.86198 1.68799 8.00111C1.68799 8.14024 1.7188 8.27765 1.77822 8.40345C2.36874 9.6472 3.07967 10.6441 3.91103 11.3942L2.25759 13.0472C2.23417 13.0706 2.22101 13.1024 2.22101 13.1356C2.22101 13.1687 2.23417 13.2005 2.25759 13.2239L2.92462 13.891C2.94806 13.9144 2.97984 13.9275 3.01298 13.9275C3.04612 13.9275 3.0779 13.9144 3.10134 13.891L14.2287 2.76408C14.2403 2.75247 14.2495 2.73868 14.2558 2.72351C14.2621 2.70833 14.2653 2.69207 14.2653 2.67564C14.2653 2.65921 14.2621 2.64295 14.2558 2.62777C14.2495 2.6126 14.2403 2.59881 14.2287 2.5872ZM2.8329 8.00033C4.13447 5.3097 5.97978 4.03158 8.50009 4.03158C9.35228 4.03158 10.1271 4.17783 10.8301 4.47517L9.73165 5.57361C9.21145 5.29605 8.6158 5.19304 8.0326 5.27977C7.44939 5.36651 6.9095 5.63839 6.49258 6.05532C6.07565 6.47224 5.80377 7.01213 5.71704 7.59534C5.6303 8.17854 5.73331 8.77418 6.01087 9.29439L4.70743 10.5978C3.98603 9.96111 3.36415 9.09845 2.8329 8.00033ZM6.68759 8.00033C6.68786 7.7248 6.75313 7.45323 6.87808 7.20767C7.00303 6.9621 7.18414 6.74948 7.4067 6.58706C7.62926 6.42464 7.887 6.31702 8.15898 6.27293C8.43095 6.22883 8.70949 6.24952 8.97196 6.3333L6.77056 8.5347C6.71538 8.36195 6.68739 8.18168 6.68759 8.00033Z" fill="currentColor"/>
    <path d="M8.43761 9.75017C8.38355 9.75017 8.33027 9.74767 8.27746 9.74283L7.45215 10.5681C7.94854 10.7582 8.48937 10.8005 9.00925 10.6898C9.52914 10.579 10.0058 10.3201 10.3817 9.94425C10.7575 9.56839 11.0165 9.0917 11.1272 8.57182C11.2379 8.05193 11.1957 7.5111 11.0056 7.01471L10.1803 7.84002C10.1851 7.89283 10.1876 7.94611 10.1876 8.00018C10.1877 8.23002 10.1426 8.45764 10.0547 8.67002C9.96675 8.88239 9.83785 9.07536 9.67532 9.23788C9.5128 9.40041 9.31983 9.52931 9.10745 9.61721C8.89508 9.70511 8.66746 9.75029 8.43761 9.75017Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'InvisibleIcon'
}
</script>

<style scoped>

</style>
